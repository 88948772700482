<template>
    <div style="background-color: rgb(248, 250, 251);">
        <NavBarNew :background='true'/>
        <ProfileNavBar />
        <main class="container text-left" :class="{empty: !currentConversationId}">
            <div class="d-flex justify-content-between">
                <div v-if="$apollo.loading" class="text-center">
                    <h5>{{ $t("loading.loading") }}</h5>
                </div>
                <h5 v-else-if="conversations.length < 1 && !$route.params.newConversationPostId" class="text-center w-100">{{ $t("public.messagesPage.noConversation") }}</h5>
                <div v-else class="conversations-list-container">
                    <div v-for="conversation in sortedConversations" :key="conversation.id" class="conversation-overview"
                        :class="{active: currentConversationId === conversation.id}" 
                        @click="setCurrentConversation(conversation)"
                    >
                        <img v-if="getAvatarFileName(conversation)" class="user-picture" :src="userPictureFilePath(getAvatarFileName(conversation))" alt="">
                        <div v-else class="user-picture" >{{getUserName(conversation).split(' ').map(function(item){return item[0]}).join('').substring(0,3)}}</div>
                        <div class="d-flex w-100">
                            <div class="conversation-info" :class="{unread: conversation.hasUnreadMessage}">
                                <p class="post-title">{{ conversation.pinboardPost.title }}</p>
                                <p class="username">{{ getUserName(conversation) }}</p>
                                <p class="message">{{ conversation.postMessages.length > 0 ? conversation.postMessages[0].message : $t("public.messagesPage.newConversation") }}</p>
                            </div>
                            <p class="date">{{ conversation.postMessages.length > 0 ? formatDate(conversation.postMessages[0].createdAt) : "" }}</p>
                        </div>
                    </div>
                </div>
                <Conversation v-if="currentConversationId" :conversationId="currentConversationId" :userName="conversationUsername" :pinboardPostTitle="pinboardPostTitle" :temporaryConversation="temporaryConversation"
                    @closeConversation="closeConversation" @conversationCreated="conversationCreated"
                />
            </div>
        </main>
        <Footer/>
    </div>
</template>

<script>
import Footer from '@/components/Footer'
import MessageService from '@/services/MessageService'
import { CONVERSATIONS, CONVERSATION_BY_ID_IF_IT_EXISTS } from '@/api/graphql/query/MessageQuery'
import ProfileNavBar from "../components/ProfileNavBar.vue"
import Conversation from "../components/Conversation.vue"
import {CLEVERTRIP_API_URL}  from '@/definitions';
import sharedUtils from '@/utils/sharedUtils'
import { UNREAD_CONVERSATIONS_COUNT } from '../api/graphql/query/MessageQuery'

    export default {
        name: "MessagesPage",

        components: {
            Footer,
            ProfileNavBar,
            Conversation
        },

        data() {
            return {
                currentConversationId: null,
                conversationUsername: '',
                pinboardPostTitle: '',
                messages: [],
                imgURL: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
                temporaryConversation: null,
                isFirstLoad: true,
            }
        },

        beforeRouteLeave(to, from, next) {
            if (document.body.style.overflow === 'hidden') {
                document.body.style.overflow = 'auto'
            }
            next()
        },

        methods: {
            conversationCreated(conversationId) {
                this.temporaryConversation = null
                this.currentConversationId = conversationId
                this.$route.params.newConversationPostId = null
                this.$apollo.queries.conversations.refetch()
            },
            userPictureFilePath(fileName) {
                return sharedUtils.getPictureUrlLowResolution(fileName, this.imgURL)
            },
            formatDate(date) {
                let moment = require('moment')
                return moment(date).format("HH:mm")
            },
            getUserName(conversation) {
                const postOwner = conversation.postOwner
                const conversationInitiator = conversation.conversationInitiator

                if (postOwner.id == this.currentUserId ) {
                    return conversationInitiator.userName
                } else {
                    return postOwner.userName
                }
            },
            getAvatarFileName(conversation) {
                const postOwner = conversation.postOwner
                const conversationInitiator = conversation.conversationInitiator

                if (postOwner.id == this.currentUserId ) {
                    return conversationInitiator.avatarFileName
                } else {
                    return postOwner.avatarFileName
                }
            },
            closeConversation() {
                this.currentConversationId = null
                document.body.style.overflow = 'auto'
                if (this.savedScrollHeight) {
                    this.$nextTick(() => {
                        window.scroll({top: this.savedScrollHeight}) 
                    })
                }
            },
            setCurrentConversation(conversation) {
                this.currentConversationId = conversation.id
                this.conversationUsername = this.getUserName(conversation)
                this.pinboardPostTitle = conversation.pinboardPost.title
                if (window.innerWidth < 768 || window.innerHeight < 600) {
                    this.savedScrollHeight = window.top.scrollY
                    document.body.style.overflow = 'hidden'
                }
            },
            updateUnreadConversationsQuery(unreadConversationsCount) {
                const apolloClient = this.$apollo.provider.defaultClient
                    const queryData = apolloClient.readQuery({
                        query: UNREAD_CONVERSATIONS_COUNT,
                    })
                    if (queryData) {
                        let dataCopy = JSON.parse(JSON.stringify(queryData))
                        dataCopy.unreadConversationsCount = unreadConversationsCount 
                        apolloClient.writeQuery({
                            query: UNREAD_CONVERSATIONS_COUNT,
                            data: {
                                unreadConversationsCount: unreadConversationsCount,
                            },
                        })
                    }
            }
        },

        computed: {
            sortedConversations() {
                let conversations = this.conversations.map(c => {return {...c}}).sort((a, b) => {
                    // Sorting by hasUnreadMessage
                    if (a.hasUnreadMessage && !b.hasUnreadMessage) {
                        return -1;
                    } else if (!a.hasUnreadMessage && b.hasUnreadMessage) {
                        return 1;
                    }

                    const latestMessageA = a.postMessages.length > 0 ? a.postMessages[0].createdAt : null;
                    const latestMessageB = b.postMessages.length > 0 ? b.postMessages[0].createdAt : null;

                    if (latestMessageA && latestMessageB) {
                        return new Date(latestMessageB) - new Date(latestMessageA);
                    } else if (latestMessageA) {
                        return -1;
                    } else if (latestMessageB) {
                        return 1;
                    }

                    return 1
                });

                if (this.$route.params.newConversationPostId && !conversations.map(c => c.pinboardPost.id).includes(this.$route.params.newConversationPostId)) {
                    conversations.push(this.temporaryConversation)
                }
                return conversations
            },
            currentUserId() {
                return this.$store.state.currentUserId
            },
            isUserAdmin() {
                return ['ADMIN', 'ADMIN_SUPERVISOR'].includes(this.$store.state.currentUserRole)
            }
        },

        apollo: {
            conversations: {
                query: CONVERSATIONS,
                result({data}) {
                    let unreadMessagesCount = 0
                    data.conversations.forEach(conv => {
                        if (conv.hasUnreadMessage) unreadMessagesCount += 1
                    })

                    this.updateUnreadConversationsQuery(unreadMessagesCount)
                    this.$store.commit('setUnreadMessagesCount', unreadMessagesCount)

                    const newConversationPostId = this.$route.params.newConversationPostId
                    if (this.isFirstLoad && newConversationPostId) {
                        this.isFirstLoad = false

                        for (const conversation of data.conversations) {
                            if (conversation.pinboardPost.id === newConversationPostId) {
                                return this.currentConversationId = conversation.id
                            }
                        }
                        
                        const post = this.$route.params.post
                        this.temporaryConversation = {
                            id: -1,
                            pinboardPost: {
                                id: newConversationPostId,
                                title: post.title,
                                author: {
                                    userName: post.author.userName
                                },
                                description: post.description
                            },
                            postOwner: {
                                id: post.author.id,
                                userName: post.author.userName
                            },
                            conversationInitiator: {
                                id: this.$store.state.currentUserId,
                                userName: this.$store.state.currentUser
                            },
                            postMessages: [],
                            hasUnreadMessage: false
                        }
                        this.conversationUsername = this.getUserName(this.temporaryConversation)
                        this.pinboardPostTitle = post.title
                        this.currentConversationId = -1
                    }
                },
                fetchPolicy: 'network-only',
                pollInterval: 15000,
            },
        }
    }

</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    main {
        padding-block: 50px 60px;
        position: relative;

        &.empty {
            min-height: 715px;
        }

        .conversations-list-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 360px;
            max-height: 550px;
            overflow: auto;
            border-top: 1px solid $tr-light-gray;

            @media (max-width: 992px) {
                max-width: 240px;
            }

            @media (max-width: 768px) {
                max-width: 100%;
                &.hide {
                    display: none;
                }
            }

            .conversation-overview {
                padding-block: 12px 17px;
                display: flex;
                align-items: center;
                cursor: pointer;
                border-bottom: 1px solid $tr-light-gray;
                position: relative;

                &.active {
                    border-bottom: 1px solid $tr-green;

                    &::after {
                        content: "";
                        position: absolute;
                        border-top: 1px solid $tr-green;
                        top: -0.5px;
                        height: 1px;
                        width: 100%;
                    }
                }

                .user-picture {
                    width: 40px;
                    height: 40px;
                    aspect-ratio: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;
                    border-radius: 50%;
                    flex-shrink: 0;
                    background-color: $tr-green;
                }

                p {
                    margin: 0;
                }

                .message, .date {
                    font-size: 12px;
                    line-height: 16.5px;
                }
                .conversation-info {
                    width: 100%;

                    .username {
                        color: $tr-gray;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 8px;
                        
                    }
    
    
                    .message, .post-title {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }

                    .message {
                        color: $tr-gray;
                    }

                    &.unread {
                        .username, .message {
                            color: $tr-black;
                        }

                        .post-title {
                            font-weight: 600;
                        }
                    }
                }


                .date {
                    color: $tr-gray;
                    line-height: 30px;
                    padding-left: 10px;
                }
            }
        }
    }

</style>