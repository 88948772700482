import { apolloClient } from "@/api/apolloClient";
import { CREATE_OR_GET_CONVERSATION, MARK_MESSAGES_AS_SEEN, CREATE_MESSAGE } from "@/api/graphql/mutation/MessageMutation";
import { CONVERSATIONS } from '@/api/graphql/query/MessageQuery'

export default {
  createConversation: function(postId) {
    console.log("Start to creating conversation for post ID: " + postId);

    return apolloClient.mutate({
      mutation: CREATE_OR_GET_CONVERSATION,
      variables: {
        postId,
      },
    }).then((result) => {
      console.log("Conversation created: ", result);
      return result;
    });
  },

  markMessagesAsSeen: function(lastMessage, conversationId) {
    console.log("Marking messages as seen for conversation ID: " + conversationId);
    const lastMessageDate = lastMessage.createdAt

    return apolloClient.mutate({
      mutation: MARK_MESSAGES_AS_SEEN,
      variables: {
        lastMessageDate,
        conversationId,
      },
      update: (store, {data: {unreadConversationsCount}}) => {
        let data = store.readQuery({ query: CONVERSATIONS })
        let dataCopy = JSON.parse(JSON.stringify(data))
        let conversationIndex = dataCopy.conversations.findIndex(conv => conv.id === conversationId);

        if (conversationIndex !== -1) {
          dataCopy.conversations[conversationIndex].hasUnreadMessage = false
          dataCopy.conversations[conversationIndex].postMessages[0] = {...lastMessage};
        }
        store.writeQuery({ query: CONVERSATIONS, data: dataCopy })
        }
    }).then((result) => {
      console.log("Messages marked as seen: ", result);
      return result;
    });
  },

  createMessage: function(text, conversationId) {
    console.log("Start to creating message for conversation ID: " + conversationId);

    return apolloClient.mutate({
      mutation: CREATE_MESSAGE,
      variables: {
        text,
        conversationId,
      },
      update: (store, {data: {createMessage}}) => {
        let data = store.readQuery({ query: CONVERSATIONS })
        let dataCopy = JSON.parse(JSON.stringify(data))
        let conversationIndex = dataCopy.conversations.findIndex(conv => conv.id === conversationId);
  
        if (conversationIndex !== -1) {
          dataCopy.conversations[conversationIndex].hasUnreadMessage = false
          dataCopy.conversations[conversationIndex].postMessages[0] = {...createMessage}
        }
        store.writeQuery({ query: CONVERSATIONS, data: dataCopy })
        }
    })
    .then((result) => {
      console.log("Message created: ", result);
      return result;
    });
  },
};
