import gql from "graphql-tag";

export const CREATE_OR_GET_CONVERSATION = gql`
    mutation createOrGetConversation($postId: ID!) {
        createOrGetConversation(postId: $postId) {
            id
        }
    }`
export const MARK_MESSAGES_AS_SEEN = gql`
    mutation markMessagesAsSeen($lastMessageDate: Date!, $conversationId:ID!) {
        markMessagesAsSeen(lastMessageDate: $lastMessageDate, conversationId: $conversationId)
    }`
export const CREATE_MESSAGE = gql`
    mutation createMessage($text: String!, $conversationId: ID!) {
        createMessage(text: $text, conversationId: $conversationId) {
            id
            senderId
            conversationId
            createdAt
            message
            seenAt
        }
    }`
